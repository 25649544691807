import React from 'react';
// import Testimonial from './TestimonialSection';
import Breadcrumb from '../../components/Breadcrumb';
// import Contact from '../../components/Contact';
// import CTA from '../../components/CTA';
// import Brand from '../../components/Brand';

import aboutImg from '../../assets/img/about/about-6.png';
// import bgImg from '../../assets/img/cta/cta-bg-2.png';
// import videoBg from '../../assets/img/video/video-bg-4.png';
// import videoImg from '../../assets/img/video/video-bg-6.png';


import CTA from '../home/CtaSection';

const HomeMain = () => {
	return (
		<main className='about-page-wrapper'>
			<Breadcrumb
				pageTitle="Refund Policy"
			/>

			{/* <Brand
				itemClass="brand-2__area brand-2__space"
			/> */}

<div className="about-3__area about-3__space about-3__plr about-3__plr-2">
  <div className="container-fluid">
    <div className="row">
      <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
        <div className="service-details__section-box">
          <h4 className="section-title text-center">Refund Policy for Buzz Global Marketing Pvt Ltd</h4>

          {/* Terms and Conditions content goes here */}
          <div dangerouslySetInnerHTML={{ __html: `
            <br>
            <p>At Buzz Global Marketing Pvt Ltd, we are dedicated to providing top-quality services and ensuring the satisfaction of our clients. To maintain transparency and set clear expectations, we would like to inform you about our No Refund Policy.</p>

            <p><strong>No Refunds</strong></p>
            <p>Once a payment is made for services rendered by Buzz Global Marketing Pvt Ltd, it is considered final. We do not offer refunds under any circumstances. By making a payment for our services, you acknowledge and agree to this policy.</p>

            <p><strong>Why We Do Not Offer Refunds</strong></p>
            <p>Our services are designed to deliver immediate, tangible value. The nature of our work requires us to allocate resources, time, and effort right from the moment a project begins. These efforts are integral to the service we provide, and once the work is underway, it is not feasible to reverse the transaction.</p>
            <p>Some of the key reasons for our no-refund policy include:</p>
            <ul>
              <li><strong>Immediate Resource Allocation:</strong> Upon payment, we begin working on your project, dedicating time, personnel, and resources to deliver the agreed-upon services.</li>
              <li><strong>Service Customization:</strong> Our services are tailored to your unique needs and objectives, making it difficult to reverse or refund once the process has started.</li>
              <li><strong>Sustaining Quality and Affordability:</strong> By not offering refunds, we are able to maintain competitive pricing while ensuring the high quality of the services we deliver. This policy also helps us reinvest in ongoing innovation and improvement, ultimately benefiting our clients.</li>
            </ul>
            <br>

            <p><strong>Clear Communication Before Payment</strong></p>
            <p>We understand that making a decision to invest in services is an important one. To ensure you are fully informed before proceeding, we encourage all prospective clients to reach out to us with any questions or concerns. Our team is happy to provide the information you need to make an informed decision about our services.</p>
            <p>Before making a payment, please feel free to contact us with any clarifications you may need about the services, process, or expected outcomes. We are committed to clear and transparent communication to ensure you are confident in your decision.</p>

            <p><strong>Acknowledgment of Our Refund Policy</strong></p>
            <p>By proceeding with a payment for our services, you confirm that you understand and agree to our No Refund Policy. If you have any questions or would like further clarification, please contact us before completing the payment process.</p>

            <p><strong>Contact Us</strong></p>
            <p>If you have any questions about our refund policy, or if you need assistance with anything before making a payment, please don’t hesitate to reach out to us:</p>
            <ul>
              <li><strong>Email:</strong> digital@buzzglobalmarketing.com</li>
            </ul>
            <br>

            <p>Thank you for choosing Buzz Global Marketing Pvt Ltd. We value your trust and look forward to delivering exceptional results for your business.</p>
        `}}></div>


        </div>
      </div>
    </div>
  </div>
</div>

			<CTA />
		</main>
	);
}

export default HomeMain;