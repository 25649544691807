import React from 'react';
// import Testimonial from './TestimonialSection';
import Breadcrumb from '../../components/Breadcrumb';
// import Contact from '../../components/Contact';
// import CTA from '../../components/CTA';
// import Brand from '../../components/Brand';

import aboutImg from '../../assets/img/about/about-6.png';
// import bgImg from '../../assets/img/cta/cta-bg-2.png';
// import videoBg from '../../assets/img/video/video-bg-4.png';
// import videoImg from '../../assets/img/video/video-bg-6.png';


import CTA from '../home/CtaSection';

const HomeMain = () => {
	return (
		<main className='about-page-wrapper'>
			<Breadcrumb
				pageTitle="Privacy and Policy"
			/>

			{/* <Brand
				itemClass="brand-2__area brand-2__space"
			/> */}

<div className="about-3__area about-3__space about-3__plr about-3__plr-2">
  <div className="container-fluid">
    <div className="row">
      <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
        <div className="service-details__section-box">
          <h4 className="section-title text-center">Privacy Policy for Buzz Global Marketing Pvt Ltd</h4>

          {/* Terms and Conditions content goes here */}
          <div dangerouslySetInnerHTML={{ __html: `
            <br>
            <p>At Buzz Global Marketing Pvt Ltd, your privacy and the security of your personal data are of utmost importance to us. We are committed to safeguarding your information and ensuring that your experience on our website is both secure and transparent. This Privacy Policy outlines how we collect, use, and protect your data when you visit our website, buzzglobalmarketing.com.</p>
            <p>By using our website, you agree to the practices described in this Privacy Policy. Please read it carefully and contact us if you have any questions.</p>

            <p><strong>1. Information We Collect</strong></p>
            <p>When you visit our website, we may collect certain types of information automatically:</p>
            <ul>
              <li><strong>Personal Information:</strong> This includes data you voluntarily provide to us, such as when you fill out a contact form, request a quote, or subscribe to our newsletter. This information may include your name, email address, phone number, and other relevant details.</li>
              <li><strong>Log Data:</strong> Like many websites, we collect information through log files. This may include your IP address, browser type, operating system, referring pages, date and time of your visit, and the number of clicks. This data helps us improve our website and analyze visitor behavior.</li>
            </ul>
            <br>

            <p><strong>2. How We Use Your Information</strong></p>
            <p>We use the information we collect for several purposes, including:</p>
            <ul>
              <li><strong>Improving Website Performance:</strong> To analyze user behavior and enhance your browsing experience.</li>
              <li><strong>Communication:</strong> To respond to inquiries, send updates, provide quotes, or offer relevant marketing information, such as promotions and service updates.</li>
              <li><strong>Marketing:</strong> We may use your information to send you targeted advertisements or re-marketing efforts across various platforms (e.g., Facebook, Google).</li>
            </ul>
            <p>We will never sell, rent, or trade your personal information to third parties. Your data will only be used for purposes directly related to your interactions with Buzz Global Marketing Pvt Ltd.</p>

            <p><strong>3. Tracking Technologies</strong></p>
            <p>We use cookies and similar tracking technologies to enhance your browsing experience and gather analytics on site usage. This includes:</p>
            <ul>
              <li><strong>Google Analytics:</strong> To collect data about your usage patterns, which helps us improve our website.</li>
              <li><strong>Facebook Pixel and Google Global Site Tag:</strong> To track conversions and re-market ads to visitors who have previously engaged with our website.</li>
              <li><strong>Email Tracking:</strong> We may track open rates and click-through rates on marketing emails to evaluate the effectiveness of our campaigns.</li>
            </ul>
            <p>You can disable cookies by adjusting the settings in your browser; however, please note that doing so may affect the functionality of some parts of the website.</p>

            <p><strong>4. Third-Party Services</strong></p>
            <p>Our website may contain links to third-party websites or advertisers. Please note that this Privacy Policy applies only to our site and not to external sites. We encourage you to review the privacy policies of any third-party sites before providing any personal information.</p>
            <p>We may also use third-party services (such as Google, Facebook, and other advertising platforms) for marketing and analytics. These platforms have their own privacy policies, which we recommend reviewing for a better understanding of how your data is used.</p>

            <p><strong>5. Data Retention</strong></p>
            <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. If you no longer wish for us to retain your information, you can contact us to request its deletion.</p>

            <p><strong>6. Your Rights and Choices</strong></p>
            <p>You have certain rights regarding your personal data, including:</p>
            <ul>
              <li><strong>Access and Correction:</strong> You can request to view or update the personal data we hold about you.</li>
              <li><strong>Opt-Out:</strong> You can opt out of receiving marketing communications at any time by clicking the “unsubscribe” link in our emails or by contacting us directly.</li>
              <li><strong>Data Deletion:</strong> If you wish to delete your information from our records, please contact us, and we will make reasonable efforts to honor your request.</li>
            </ul>
            <br>

            <p><strong>7. Security</strong></p>
            <p>We implement industry-standard security measures to protect your personal data from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet is completely secure, so we cannot guarantee absolute security.</p>

            <p><strong>8. Consent</strong></p>
            <p>By accessing or using our website, you consent to the collection, use, and sharing of your information as described in this Privacy Policy. If you do not agree with any aspect of this policy, we kindly ask that you refrain from using our website.</p>

            <p><strong>9. Changes to This Privacy Policy</strong></p>
            <p>Buzz Global Marketing Pvt Ltd reserves the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We recommend that you periodically review this policy to stay informed about how we are protecting your information.</p>

            <p><strong>10. Contact Us</strong></p>
            <p>If you have any questions or concerns about this Privacy Policy or how your data is handled, please feel free to contact us at:</p>
            <ul>
              <li><strong>Email:</strong> digital@buzzglobalmarketing.com</li>
            </ul>
            <br>

            <p><strong>11. Copyright Notice</strong></p>
            <p>The content of this Privacy Policy is the intellectual property of Buzz Global Marketing Pvt Ltd. Any unauthorized use or reproduction of this policy without our permission may result in legal action.</p>

            <p><strong>12. Caution Against Fraud</strong></p>
            <p>Please be aware that we only operate under the domain buzzglobalmarketing.com. If you encounter any individuals or entities claiming to be representatives of our company for fraudulent purposes (such as offering part-time jobs or requesting payment for placement services), please report them to us immediately. We will take legal action against any such fraudulent activities.</p>

            <p>Thank you for trusting Buzz Global Marketing Pvt Ltd with your information. We value your privacy and strive to ensure your experience with us is safe, secure, and respectful.</p>
        `}}></div>

        </div>
      </div>
    </div>
  </div>
</div>

			<CTA />
		</main>
	);
}

export default HomeMain;