import React from 'react';
import ContactMain from './ContactMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="index, follow" />
                <title>Contact Us | Buzz Global</title>
                <meta name="description" content="Ready to take your digital marketing to the next level? Get in touch with Buzz Global for tailored solutions that elevate your online presence and deliver real, measurable results!" />
                <link rel="canonical" href={`${window.location.host}/caontact`}/>
            </Helmet>
            <Header
                parentMenu="contact"
                headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
            />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <ContactMain />
                    <Footer />
                </div>
            </div>
                    
        </>
    );
}

export default Contact;
