import React from 'react';
import ServiceMain from './ServiceMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {Helmet} from "react-helmet";

const Service = () => {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Services | Buzz Global - Comprehensive Digital Marketing Solutions</title>
          <meta name="description" content="Unlock your brand’s potential with Buzz Global’s comprehensive digital marketing services. From SEO to paid ads, we deliver strategies that drive success. Let’s grow your business!" />
          <link rel="canonical" href={`${window.location.host}/service`}/>
      </Helmet>
      <Header 
        parentMenu= 'service'
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <ServiceMain />
          <Footer />
        </div>
      </div>
          
    </>
  );
}

export default Service;
