import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContentServices from './contentServices';
import {Helmet} from "react-helmet";


function Development() {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Content Strategy Consulting | Content Marketing | Buzz Global</title>
          <meta name="description" content="Strengthen your brand with Buzz Global’s content strategy consulting and marketing services. We create custom content plans that engage audiences and drive results" />
          <meta name="keywords" content="Content Strategy Consulting, Content Optimization, digital content strategy, Content Marketing Strate Content Marketing  Agency" />
          <link rel="canonical" href={`${window.location.host}/service`}/>
      </Helmet>
      <Header 
        parentMenu= 'service'
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <ContentServices />
          <Footer />
        </div>
      </div>  
    </>
  );
}

export default Development