import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DevelopmentServices from './DevelopmentServices';
import {Helmet} from "react-helmet";


function Development() {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Custom Web Design & Development Services USA | Buzz Global</title>
          <meta name="description" content="Transform your online presence with Buzz Global’s custom web design and development services. We create responsive, user-friendly websites that drive business success." />
          <meta name="keywords" content="Website Development Services, Custom Website Design, Website Design & Development Services, web design services, Custom Web Development Services, Reliable Custom Web Development Agency, Website Design Company, Web Development Company" />
          <link rel="canonical" href={`${window.location.host}/service`}/>
      </Helmet>
      <Header 
        parentMenu= 'service'
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <DevelopmentServices />
          <Footer />
        </div>
      </div>  
    </>
  );
}

export default Development