import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PaidmediaServices from './paidmediaServices';
import {Helmet} from "react-helmet";


function Development() {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Paid Marketing Services | PPC Agency | Buzz Global</title>
          <meta name="description" content="Drive targeted traffic and maximize ROI with Buzz Global's paid marketing services. As a top PPC agency, we create data-driven campaigns for faster, measurable growth." />
          <meta name="keywords" content="paid marketing services, Paid Advertising Agency, PPC Agency, Pay Per Click Marketing Services, PPC management services" />
          <link rel="canonical" href={`${window.location.host}/service`}/>
      </Helmet>
      <Header 
        parentMenu= 'service'
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <PaidmediaServices />
          <Footer />
        </div>
      </div>  
    </>
  );
}

export default Development