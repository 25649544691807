import React from 'react';
import RefundMain from './RefundMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {Helmet} from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow" />
          <title>Refund Policy - Buzz Global</title>
          <meta name="description" content="Refund Policy" />
          <link rel="canonical" href={`${window.location.host}/refund-policy`}/>
      </Helmet>
      <Header
        parentMenu="about"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <RefundMain />
          <Footer />
        </div>
      </div>
          
    </>
  );
}

export default About;
