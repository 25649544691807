import React from 'react';
import AboutMain from './AboutMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {Helmet} from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>About Buzz Global - Your Partner in Digital Marketing Success</title>
          <meta name="description" content="Learn how Buzz Global empowers businesses with innovative digital marketing strategies. Our expert team is dedicated to driving growth and measurable results. Contact us today!" />
          <link rel="canonical" href={`${window.location.host}/about-us`}/>
      </Helmet>
      <Header
        parentMenu="about"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <AboutMain />
          <Footer />
        </div>
      </div>
          
    </>
  );
}

export default About;
