import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SmmServices from './smmServices';
import {Helmet} from "react-helmet";

function Development() {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="index, follow" />
          <title>Professional SMM Service Provider | Buzz Global</title>
          <meta name="description" content="Maximize your social media impact with Buzz Global's expert SMM services. We create tailored strategies to grow your audience, boost engagement, and drive real results!" />
          <meta name="keywords" content="SMM Services, Professional SMM Services, SMM Service Provider, Affordable SMM company, Social Media Marketing Agency" />
          <link rel="canonical" href={`${window.location.host}/service`}/>
      </Helmet>
      <Header 
        parentMenu= 'service'
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <SmmServices />
          <Footer />
        </div>
      </div>  
    </>
  );
}

export default Development