import React from 'react';

const Work = () => {
  return (
    <div className="work-3__area work-3__inner-wrapper work-3__plr work-3__space">
      <div className="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="project-2__section-box text-center pb-30 section-title-fixed-width">
              <h4 class="section-subtitle title-anim">Our Approach</h4>
              <p>We don’t run away from challenges. We face and go the extra mile to deliver the best.</p>
            </div>
          </div>
        </div>
        <div className="row row-cols-xl-3 row-cols-md-2 justify-content-center our-approch-wrapper">
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-1 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                  <div className="work-3__icon icon-color-1">
                    <span><i className="fas fa-lightbulb-on"></i></span>
                  </div>
                  <div className="work-3__title">
                    <h4 className="work-3__section-title"><a href="#">Transparent Communication</a></h4>
                  </div>
                </div>
                <div className="work-3__right-text">
                  <p>We don’t believe in surprises. From day one we follow a very transparent approach and maintain clear communication with our clients at every stage of the development process. Whether it’s success news, challenges, or updates, we make to deliver openly and crystal clear. We talk straight. No jargon. Yes, we speak your language to make you understand better, always.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-2 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-cogs"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">Agile Process</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>We don’t work in siloes like a traditional agency. At Buzz, we plan, design, and implement every strategy with a flexible approach to produce better results. We don’t just hand over and disappear. We are in continuous collaboration with you until your goals and objectives are met.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-3 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-chart-bar"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">Interdisciplinary Approach</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>We never settle for one perspective. Instead, we conduct brainstorming sessions with our team to pull the best ideas from all corners of expertise. With a fresh mix of ideas and proven expertise, we sit with you to decide on your marketing plans and policy to make your brand stand out from the crowded marketplace.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;