import React from 'react';
import SingleService from '../../components/Service';
import Breadcrumb from '../../components/Breadcrumb';
import CTA from '../home/CtaSection';

import bgImg from '../../assets/img/cta/cta-bg-2.png';
import serviceIconBg1 from '../../assets/img/service/sv-icon-shape-13.png'
import serviceIconBg2 from '../../assets/img/service/sv-icon-shape-14.png'
import serviceIconBg3 from '../../assets/img/service/sv-icon-shape-15.png'
import serviceIconBg4 from '../../assets/img/service/sv-icon-shape-16.png'
import serviceIconBg5 from '../../assets/img/service/sv-icon-shape-17.png'
import serviceIconBg6 from '../../assets/img/service/sv-icon-shape-18.png'
import serviceIconBg7 from '../../assets/img/service/sv-icon-shape-19.png'
import serviceIconBg8 from '../../assets/img/service/sv-icon-shape-20.png'
import serviceIconBg9 from '../../assets/img/service/sv-icon-shape-21.png'
import serviceIconBg10 from '../../assets/img/service/sv-icon-shape-24.png'
import serviceIconBg11 from '../../assets/img/service/sv-icon-shape-22.png'
import serviceIconBg12 from '../../assets/img/service/sv-icon-shape-23.png'

import BrandDesignStrategy from '../../assets/img/service/Brand-Design-Strategy.png';
import SocialMediaStrategy from '../../assets/img/service/Social-Media-Strategy.png';
import DataInsights from '../../assets/img/service/Data-Insights.png';
import CreativeAndContent from '../../assets/img/service/Creative-and-content.png';
import EmailMarketing from '../../assets/img/service/Email-Marketing.png';
import PaidMedia from '../../assets/img/service/paid-media.png';
import DesignandDevelopment from '../../assets/img/service/Design-and-Development.png';
import SEO from '../../assets/img/service/SEO.png';
import WhiteLabel from '../../assets/img/service/White-Label.png';
import DedicatedResource from '../../assets/img/service/Dedicated-Resource.png';
import ourCompany from '../../assets/img/company/our-company.png';

const ServiceMain = () => {
	return (
		<main>

			<Breadcrumb
				pageTitle="Service"
			/>

			<div className="service__area service__plr mt-100 mb-20 p-relative">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-6 wow animate__fadeInLeft" data-wow-duration="1.1s">
							<h4 className="section-about-text char-anim">Get Your Business A Global Outreach with Our 360 Degree Marketing Solutions</h4>
							<div className="company__left-img text-center text-xl-start">
								<img src={ourCompany} alt="" />
							</div>
						</div>
						<div className="col-xl-6 wow animate__fadeInRight" data-wow-duration="1.1s">
							<div className="company__section-box">

								<p className="char-anim-2">
									Ready to take your business across the international frontier targeting the right segment of audience? Our 360 degree marketing solutions are designed to capitalize on every interaction of your brand with the target audience.
								</p>
								<p className="char-anim-2">
									Whether you’ve a large business or a small business or regardless of the industry you serve, we create tailored marketing campaigns to attract and retain customers all over the globe by using industry proven online marketing tools.
								</p>
								<p className="char-anim-2">
									From SEO, PPC, social media to content and designing and website development, we offer a full suite of services to establish brand identity and awareness that helps you assert market expertise. Our team of experts cover all angles to give your brand the reach it deserves.
								</p>
								<p className="char-anim-2">
									Schedule a call to expand your horizons and make your brand noticed.
								</p>
							</div>
						</div>
						<div className="col-xl-12 col-lg-2 wow animate__fadeInUp text-center" data-wow-duration="1.1s">
							<div className="service-details__section-box text-center">
								<h4 className="animate-charcter section-title text-center">
									Best Digital Marketing Services in the U.S.A
								</h4>
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-one"
								btnClass="service__link service__link-color-1"
								titleClass="service__title color-1"
								descClass="text-white"
								// serviceIcon= "fal fa-bullseye"
								serviceIconBg={serviceIconBg1}
								serviceImage={BrandDesignStrategy}
								Title="Brand Discovery & Strategy"
								Description="We cut through the noise with a well-devised brand strategy. Our expertise spans creating collaterals, and brand architecture to deliver human-centric experiences that deeply connect your brand with people’s interests. With our dedicated brand strategy team, we help you with brand naming, logo design and style guide, brand launch strategy, and overall rebranding services to establish a strong brand identity in the market."
								btnText="Read More"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-one"
								btnClass="service__link service__link-color-2"
								titleClass="service__title color-2"
								descClass="text-white"
								// serviceIcon= "fal fa-handshake-slash"
								serviceIconBg={serviceIconBg2}
								serviceImage={SocialMediaStrategy}
								Title="Social Media Strategy"
								Description="We convert likes into leads with our personalized social media strategy & campaign that gives you more than just visibility. Expand your brand's reach on the internet’s hottest social sites and build a robust brand identity. Our affinity with social media platforms can bring your business to the flight with customized plans and strategies. Build an emotional touch, reach the exact audience & measure your ad campaigns effectively."
								btnText="Read More"
								linkPage="/smm"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-one"
								btnClass="service__link service__link-color-3"
								titleClass="service__title color-3"
								descClass="text-white"
								// serviceIcon= "fal fa-database"
								serviceIconBg={serviceIconBg3}
								serviceImage={DataInsights}
								Title="Data and Insights"
								Description="We convert data into insights that you didn’t even know you had. Empower your business with our post-campaign evaluations for strategic decision-making and sustained growth. Gain deep insights into what your audiences actually want with our solid marketing data and insight services. At Buzz, we get the most out of your marketing budget by deeply analyzing your marketing performance, customer segmentation, marketing mix, customer acquisition, cross-sell and up-sell analytics, and loyalty analysis."
								btnText="Read More"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-one"
								btnClass="service__link service__link-color-4"
								titleClass="service__title color-4"
								descClass="text-white"
								// serviceIcon= "fal fa-lightbulb-on"
								serviceIconBg={serviceIconBg4}
								serviceImage={CreativeAndContent}
								Title="Creative and Content"
								Description="Boost conversation and SEO performance with pinpointed and researched content. Our proven writers deliver authoritative content that makes your brand a top-notch industry leader. From copywriting, and guest blogging to content marketing, we offer everything to get you on the first page of Google. Reach out to us to develop a solid pillar page and cluster page strategy for game-changing results."
								btnText="Read More"
								linkPage="/creative-content"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-one"
								btnClass="service__link service__link-color-5"
								titleClass="service__title color-5"
								descClass="text-white"
								// serviceIcon= "fas fa-envelope"
								serviceIconBg={serviceIconBg5}
								serviceImage={EmailMarketing}
								Title="Email Marketing Automation"
								Description="Secure the rapt attention of your subscribers by sending the right message at the right time. At Buzz, we help you with email writing & design, ⁠HTML Emailers, setup & schedule, ⁠and reporting to enhance the efficiency of your marketing campaigns. Whether you’re a B2B or B2C, our in-house marketing team has the expertise to leverage powerful email marketing platforms to automate email sequences, personalize content, and optimize campaign performance to nurture your relationship with customers."
								btnText="Read More"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-one"
								btnClass="service__link service__link-color-6"
								titleClass="service__title color-6"
								descClass="text-white"
								// serviceIcon= "fal fa-code"
								serviceIconBg={serviceIconBg6}
								serviceImage={DesignandDevelopment}
								Title="Design and Development"
								Description="We bring your ideas to life by leveraging the best tools and latest technologies. As a leading agency, our time-tested designers and developers offer ROI-focused website designing, focusing on the overall interface of your web page or landing page design. Collaborate with us now to create an intuitive and user-friendly website that works well on all devices, increasing your dwell time for optimized SEO."
								btnText="Read More"
								linkPage="/design-development"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-two"
								btnClass="service__link service__link-color-7"
								titleClass="service__title color-7"
								descClass="text-white"
								// serviceIcon= "fas fa-cog"
								serviceIconBg={serviceIconBg7}
								serviceImage={SEO}
								Title="Search Engine Optimization"
								Description="We get your business noticed. With our ON-Page & Off-Page solutions, we dominate search results and bring your offerings into the spotlight. Teaming up with our certified SEO Analyst, you can expect unparalleled SEO services from us. From mobile optimization, desktop optimization, and keyword optimization to content syndication, we offer everything to make your business stand out. Get better in search ranking."
								btnText="Read More"
								linkPage="/seo"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none"
								btnClass="service__link service__link-color-8"
								titleClass="service__title color-8"
								descClass="text-white"
								// serviceIcon= "fad fa-chess-clock-alt"
								serviceIconBg={serviceIconBg10}
								serviceImage={PaidMedia}
								Title="Paid Media Services"
								Description="Precisely target your audience, and reach new consumers with personalized data-driven campaigns to get in front of the right audience at the right time. Our 360 Degree Growth Marketing Solutions understand your customer behavior before you reach and create campaigns that work best for your business. Count on us to lower your costs and increase revenue through rigorous keyword research & ad and landing page testing. Let's Elevate Your Brand Together – Start Your Custom Campaign Today!"
								btnText="Read More"
								linkPage="/paid-media"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-two"
								btnClass="service__link service__link-color-9"
								titleClass="service__title color-9"
								descClass="text-white"
								// serviceIcon= "fad fa-chess-clock-alt"
								serviceIconBg={serviceIconBg9}
								serviceImage={WhiteLabel}
								Title="White Label Services"
								Description="We’ll take care of your clients just like you do. Our white label experts handle all your client’s requests for PPC, SEO, or web development ensuring on-time delivery to help you increase referrals and maintain a client retention ratio. We’ll setup a discovery call to understand your project needs, proceed with strategy development to determine your plan of action, and help you with overall white label reporting solutions so you stay informed without lifting a finger. Let’s roll up the sleeves to get your brand the spotlight it deserves!"
								btnText="Read More"
							>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass="service__item service__item-bg p-relative fix shape-none fix-height-two"
								btnClass="service__link service__link-color-10"
								titleClass="service__title color-10"
								descClass="text-white"
								// serviceIcon= "fad fa-users"
								serviceIconBg={serviceIconBg10}
								serviceImage={DedicatedResource}
								Title="Dedicated Resources"
								Description="Accomplish your dream project by hiring the most qualified and skilled resources with flexibility and cost-effectiveness. Hire the best talent for SEO, SEM, SMM, Shopify developers, Wordpress developers, PHP developers or UI/UX designs to meet the highest standards, ensuring a superior end-product. At Buzz, we take care of everything - finding the right people, hiring them and making sure that they’re working in synergy with your team."
								btnText="Read More"
							>
							</SingleService>
						</div>
						<div className="col-xl-12 col-lg-2 wow animate__fadeInUp text-center" data-wow-duration="1.1s">
							<div className="service-details__section-box text-center">
								<p className="char-anim-2">We can have 👇</p>
								<h4 className="animate-charcter section-title text-center">
									Marketing Offers
								</h4>
								<p className="char-anim-2">Get more done with 360 Marketing Agency Packages</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<CTA />

		</main>
	);
}

export default ServiceMain;