import React from 'react';
// import Testimonial from './TestimonialSection';
import Breadcrumb from '../../components/Breadcrumb';
// import Contact from '../../components/Contact';
// import CTA from '../../components/CTA';
// import Brand from '../../components/Brand';

import aboutImg from '../../assets/img/about/about-6.png';
// import bgImg from '../../assets/img/cta/cta-bg-2.png';
// import videoBg from '../../assets/img/video/video-bg-4.png';
// import videoImg from '../../assets/img/video/video-bg-6.png';

import Team from './TeamSection';
import Feature from './FeatureSection';
import Work from './WorkSection';
import CTA from '../home/CtaSection';

const HomeMain = () => {
	return (
		<main className='about-page-wrapper'>
			<Breadcrumb
				pageTitle="About Us"
			/>

			{/* <Brand
				itemClass="brand-2__area brand-2__space"
			/> */}

			<div className="about-3__area about-3__space about-3__plr about-3__plr-2">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
							<div className="service-details__section-box">
								<h4 className="animate-charcter section-title text-center">
									We are a Full-Service Branding, Creative, and Digital Marketing Agency
								</h4>
								<p className="text-center">
									{" "}
									Whether it be website development, social media management, Google & Meta ads, SEO, or copywriting and content, our in-house team has the required skillset and experience to serve you nothing, BUT THE BEST.
								</p>

							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-xl-6 col-lg-6 col-md-12 mb-70 wow animate__fadeInLeft" data-wow-duration="1.1s">
							<div className="about-3__item-img p-relative">
								<img src={aboutImg} alt="" />
								{/* <div className="about-3__button about-3__button-wrapper d-flex align-items-center" style={{ display: 'none' }}>
									<div className="about-3__video">
										<a href="#"><i className="fas fa-play"></i></a>
									</div>
									<div className="about-3__video-text" >
										<a href="#"><span>Watch Video</span></a>
									</div>
								</div> */}
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 mb-70 wow animate__fadeInRight" data-wow-duration="1.1s">
							<div className="about-3__item about-3__wrapper-item">
								<div className="about-3__section-title pb-10">
									<h4 className="section-blue-subtitle char-anim">About Buzz Global</h4>
									<h3 className="section-title char-anim">Who We Are</h3>
								</div>
								<div className="about-3__content">
									<div className="about-3__text pb-7">
										<p className="char-anim-2">Buzz Global is the leading digital marketing agency in the USA that pioneers innovative strategies and high-tech solutions to boost businesses’ online presence and serve a diverse range of industry verticals with tailored services.</p>
										<p className="char-anim-2">As a trusted business partner, we’ll help build profitable relationships with your audiences and conquer the digital space with personalized ad campaigns and content strategy. Our goal is not just to deliver 360-degree marketing solutions but to become an extension of your one-stop marketing team ensuring an end -to end collaborative partnership</p>
										<p className="char-anim-2">With our industry knowledge and creative prowess, our team strives to get your business in front of the right crowd and present it in a way that turns clicks into loyal customers. We’re here to help from setting up the first step to maintenance and support and beyond.</p>
										<p className="char-anim-2">Your brand deserves to be seen, and we’re here to make sure it is. Let’s connect today to discuss your business!</p>
									</div>
									<div className="about-3__content-list about-3__content-list-2">
										<ul>
											{/* <li><i className="fal fa-check-square"></i><span>Business sagittis aliquaenim minim veniam leo.</span></li> */}
											<li><span>“We never take shortcuts. We don’t settle for cookie-cutter solutions. With over a decade of experience, our team sets & exceeds our own benchmarks. And, we measure our success by one thing: Your Satisfaction. We’re highly committed to planning, strategizing, and implementing the best practices for your business that deliver nothing but real results.” <br /> - Core Committee at Buzz Global</span></li>
										</ul>
									</div>
									{/* <div className="about-3__button">
										<a className="main-btn-sm tp-btn-hover alt-color" href="#">
											<span>About More</span><b></b>
										</a>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
							<div className="service-details__section-box">
								<h4 className="section-title text-center">
									What We Do
								</h4>
								<p className="text-center">
									{" "}
									At Buzz Global, we don’t just do digital marketing—we make it an art. We craft compelling brand launch campaigns - from design to content and expand your market reach to deliver tangible outcome with ROI-driven digital marketing approach.
								</p>

							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
							<div className="service-details__section-box">
								<h4 className="section-title text-center">
								  What else do we do?
								</h4>
								<p className="text-center">
									{" "}
									We push you to the front page of search results, where your customers are looking, and make your brand feel noticed. With finely-tuned pay-per-click ads, we make every click count.
								</p>
								<p className="text-center">
									{" "}
									Whether it’s Insta-worthy posts, tweetstorms, TikTok visibility, or LinkedIn networking, we make your social presence irresistible. Likes, shares, and follows? We bring the whole package to you.
								</p>
								<p className="text-center">
									{" "}
									Ready to leave your competitors in the dust? It’s time to up the ante and invest in the best digital marketing agency for your ultimate business growth.
								</p>

							</div>
						</div>
					</div>
				</div>
			</div>

			<Work />

			{/* <Feature /> */}

			<Team />

			{/* <Testimonial /> */}

			{/* <div className="funfact-2__area funfact-2__space">
				<div className="funfact-2__bg fix" style={{backgroundImage: `url(${videoBg})`}}>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-xl-6 col-lg-6 col-md-7">
								<div className="funfact-2__content">
									<div className="funfact-2__text wow animate__fadeInUp" data-wow-duration=".7s">
									<h4 className="section-title text-white char-anim wow animate__fadeInUp" data-wow-duration=".9s">Our Business Service <br/>
										Apps of Agency.</h4>
									</div> 
									<div className="funfact-2__button wow animate__fadeInUp" data-wow-duration="1.1s">
									<a className="border-btn-3 bg-color-1 mb-20 mr-15" href="#"><i className="fab fa-apple"></i> App Store</a>
									<a className="border-btn-3 bg-color-2 mb-20" href="#"><i className="fas fa-cloud"></i>Play Store</a>
									</div> 
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-5">
								<div className="funfact-2__main-img d-none d-md-block wow animate__fadeInUp" data-wow-duration="1.1s">
									<img src={videoImg} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

			{/* <Contact /> */}

			<CTA />
		</main>
	);
}

export default HomeMain;