
import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo/logo.png';
import footerBG from '../../assets/img/logo/logo.jpg';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer>
            <div className={footerClass ? footerClass : 'footer__area footer__plr'}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xxl-5 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-1">
                                <div className="footer__logo">
                                    <Link to="/">
                                        <img style={{maxWidth: "48%"}} src={Logo} alt="" />
                                        {/* <h6 style={{color: '#fff'}}>Buzz Global Marketing</h6> */}
                                    </Link>
                                </div>
                                <div className="footer__content">
                                <div className="footer__text">
                                    <p>We're an ever-evolving 360-degree Digital Marketing Agency that looks ahead to what's next, what's better. Because delivering the best for our clients is our benchmark.</p>
                                </div>
                                {/* <div className="footer__number-wrapper d-flex align-items-center">
                                    <div className="footer__number-icon">
                                        <span><i className="fal fa-phone-alt"></i></span>
                                    </div>
                                    <div className="footer__number-info">
                                        <span>Have Any Questions?</span>
                                        <a href="tel:+230045647823">(+1) 456 1122 7890</a>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-7 mb-70 wow animate__fadeInUp" data-wow-duration="1.1s"></div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-2">
                                <h4 className="footer__title"><Link to="#">Quick Links</Link></h4>
                                <div className="footer__menu-list">
                                <ul>
                                    <li><a href="/services">Services</a></li>
                                    {/* <li><a href="/project">Portfolio</a></li> */}
                                    <li><a href="/about-us">About</a></li>
                                    <li><a href="/blog">Blog</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-3">
                                <h4 className="footer__title">
                                    <a href="#">Support Link</a>
                                </h4>
                                <div className="footer__menu-list">
                                    <ul>
                                        <li><a href="#">Web Design</a></li>
                                        <li><a href="#">Development</a></li>
                                        <li><a href="#">WordPress</a></li>
                                        <li><a href="#">Online Marketing</a></li>
                                        <li><a href="#">Content</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-4">
                                {/* <h4 className="footer__title"><a href="#">Newsletter</a></h4>
                                <div className="footer__content">
                                <p>Our approach to it is unique around
                                    know work as we know.</p>
                                </div>
                                <div className="footer__input">
                                    <form action="#">
                                        <input type="email" placeholder="Your email" />
                                        <button className="subscribe-btn">Subscribe </button>
                                    </form>
                                </div> */}
                                <div className="footer__social-box">
                                    <p>Social Media:</p>
                                    <be/>
                                    <a href="https://www.facebook.com/profile.php?id=61560468886066&mibextid=LQQJ4d"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/buzzglobalmarketing?igsh=MW5oNWN2OGNtejZtMQ%3D%3D&utm_source=qr"><i className="fab fa-instagram"></i></a>
                                    {/* <a href="#"><i className="fab fa-google-plus-g"></i></a> */}
                                    <a href="https://www.youtube.com/@BuzzGlobalMarketing"><i className="fab fa-youtube"></i></a>
                                    <a href="https://www.linkedin.com/company/buzzglobalmarketing"><i className="fab fa-linkedin"></i></a>
                                    {/* <FontAwesomeIcon icon="fa-brands fa-youtube" /> */}
                                    {/* <FontAwesomeIcon icon="fa-brands fa-linkedin" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="copyright__area copyright__plr black-bg-3">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 wow animate__slideInUp" data-wow-duration="1.1s">
                            <div className="copyright__text">
                                <span>Copyright © 2024 Buzz Global Marketing, All Right Reserved</span>
                            </div>
                        </div>
                        <div className="col-md-6 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="copyright__item text-start text-md-end">
                                <span><a href="#">Terms of Service</a></span>
                                <span><a href="#">Privacy & Policy</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;