import React from 'react';
import HomeMain from './HomeMain';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';


const Home = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="index, follow" />
                <title>Full Service Digital Marketing Agency USA | Buzz Global</title>
                <meta name="description" content="Buzz Global, a full-service digital marketing agency, delivers 360-degree solutions that boost your brand’s online presence. Partner with us to achieve measurable growth today!" />
                <meta name="keywords" content="Online Marketing Solutions, Full Service Digital Marketing Agency, Full Service Digital Marketing Services, Full Service Digital Marketing, Digital Marketing Solutions, 360 degree marketing solutions, 360 Degree Digital Marketing Solution, 360 Degree Growth Marketing Solutions" />
                <link rel="canonical" href={`${window.location.host}`}/>
            </Helmet> 
            <Header
                parentMenu='home'
            />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <HomeMain />
                    <Footer />
                </div>
            </div>
                    
        </>
    );
}

export default Home;
