import React from 'react';
// import Testimonial from './TestimonialSection';
import Breadcrumb from '../../components/Breadcrumb';
// import Contact from '../../components/Contact';
// import CTA from '../../components/CTA';
// import Brand from '../../components/Brand';

import aboutImg from '../../assets/img/about/about-6.png';
// import bgImg from '../../assets/img/cta/cta-bg-2.png';
// import videoBg from '../../assets/img/video/video-bg-4.png';
// import videoImg from '../../assets/img/video/video-bg-6.png';


import CTA from '../home/CtaSection';

const HomeMain = () => {
	return (
		<main className='about-page-wrapper'>
			<Breadcrumb
				pageTitle="Terms and Conditions"
			/>

			{/* <Brand
				itemClass="brand-2__area brand-2__space"
			/> */}

<div className="about-3__area about-3__space about-3__plr about-3__plr-2">
  <div className="container-fluid">
    <div className="row">
      <div className="col-xl-12 col-lg-2 wow animate__fadeInUp" data-wow-duration="1.1s">
        <div className="service-details__section-box">
          <h4 className="section-title text-center">Terms and Conditions for Buzz Global Marketing Pvt Ltd</h4>

          {/* Terms and Conditions content goes here */}
          <div dangerouslySetInnerHTML={{ __html: `
            <br>
            <p>Last updated : 1st November 2024</p>
            <p>Welcome to Buzz Global Marketing Pvt Ltd. By accessing or using the services offered by Buzz Global Marketing Pvt Ltd ("we", "us", "our"), you ("you", "the user", "client") agree to be bound by the following Terms and Conditions. Please read them carefully. If you do not agree to these Terms and Conditions, you should refrain from using our services.</p>
            
            <p><strong>1. Acceptance of Terms</strong></p>
            <p>By accessing and using any of the services provided by Buzz Global Marketing Pvt Ltd, you acknowledge and agree to comply with these Terms and Conditions. These terms constitute a legally binding agreement between you and Buzz Global Marketing Pvt Ltd. If you are using our services on behalf of a business or organization, you represent and warrant that you have the authority to bind that entity to these Terms.</p>
            <p>We may update, modify, or change these Terms and Conditions at any time without prior notice. You should review these terms regularly, as continued use of our services constitutes your acceptance of the updated terms.</p>
            
            <p><strong>2. Service Description</strong></p>
            <p>Buzz Global Marketing Pvt Ltd offers a wide range of services, including but not limited to:</p>
            <ul>
              <li>Digital Marketing Services</li>
              <li>Website Development</li>
              <li>Technology Solutions</li>
              <li>Content Creation & Design Services</li>
            </ul>
            <p>We reserve the right to modify, update, or discontinue any service at our discretion. Service offerings, pricing, and availability may be subject to change based on business needs, industry trends, or technological advancements. You will be notified of any significant changes to the services you have contracted with us.</p>
            
            <p><strong>3. User Responsibilities</strong></p>
            <p>As a user of our services, you agree to:</p>
            <ul>
              <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information, including login credentials, passwords, and other sensitive data.</li>
              <li><strong>Appropriate Use:</strong> You agree not to engage in any activity that disrupts, interferes with, or negatively impacts the functioning of our services or any systems associated with them.</li>
              <li><strong>Compliance:</strong> You agree to comply with all applicable laws and regulations when using our services.</li>
              <li>You are also responsible for the accuracy, quality, and legality of any data or content you provide to us in connection with the services.</li>
            </ul>

			<BR>
            <p><strong>4. Payment Terms</strong></p>
            <p>All payment terms are specified in individual service agreements or contracts. Unless otherwise agreed upon, payments for services are due according to the timelines outlined in the respective agreements.</p>
            <ul>
              <li><strong>Late Payments:</strong> Failure to make timely payments may result in the suspension or termination of services. We reserve the right to charge interest or late fees on overdue amounts as outlined in the agreement.</li>
              <li><strong>Billing Information:</strong> You agree to provide accurate and complete billing information and to update it as necessary to ensure continued access to services.</li>
              <li><strong>All payments are non-refundable, unless otherwise specified in the service agreement.</strong></li>
            </ul>
            
			<BR>
            <p><strong>5. Data Usage and Privacy</strong></p>
            <p>Your privacy and the security of your data are important to us. Our use of your data is governed by our Privacy Policy, which outlines how we collect, use, store, and protect your personal information.</p>
			 <ul>
              <li><strong>Data Handling:</strong>We may use your information to respond to inquiries, provide customer support, send essential service updates, and offer marketing communications (with your consent) via email, SMS, WhatsApp, or other channels.</li>
              <li><strong>No Sale of Data:</strong> We do not sell, rent, or disclose your personal data to third parties without your explicit consent, except as required by law or as necessary to fulfill the services you request.</li>
            </ul>
			<p>By using our services, you consent to the collection, processing, and use of your data as described in our Privacy Policy.</p>

			<p><strong>6. Intellectual Property</strong></p>
            <p>All content, materials, designs, trademarks, logos, and other intellectual property provided by Buzz Global Marketing Pvt Ltd are owned by us or licensed to us and are protected by applicable copyright, trademark, and intellectual property laws.</p>
			<p>You may not reproduce, distribute, modify, or create derivative works based on our content without prior written permission. This includes but is not limited to any content we create for you as part of the services we provide.</p>

			<p><strong>7. Service Availability</strong></p>
            <p>We make reasonable efforts to ensure that our services are available to you without interruption. However, we do not guarantee 100% uptime, and temporary service interruptions may occur due to routine maintenance, technical issues, or unforeseen circumstances.</p>
			<p>We will strive to minimize disruptions and keep you informed in the event of planned maintenance or service outages.</p>

			<p><strong>8. Termination of Services</strong></p>
            <p>We reserve the right to terminate or suspend your access to our services at our sole discretion, with or without cause. Termination may occur for reasons including but not limited to non-payment, violation of these Terms and Conditions, or any other behavior that disrupts our business operations.</p>
			 <ul>
              <li><strong>User-Initiated Termination:</strong>You may terminate services in accordance with the cancellation procedures outlined in your service agreement. Please review the specific terms regarding cancellation and refunds (if applicable).</li>
            </ul>
			<p>Upon termination, you will be responsible for any outstanding fees or charges incurred up to the termination date.</p>

			<p><strong>9. Limitation of Liability</strong></p>
            <p>Buzz Global Marketing Pvt Ltd shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use or inability to use our services. This includes but is not limited to damages for loss of profits, business interruption, or loss of data.</p>
			<p>Our total liability to you for any claims arising out of the provision of services shall not exceed the total amount paid by you for the services in question.</p>

			<p><strong>10. Modifications to Terms & Conditions</strong></p>
            <p>We reserve the right to update or modify these Terms and Conditions at any time without prior notice. If significant changes are made, we will make reasonable efforts to notify users via email or through a notice on our website. Continued use of our services after such changes constitutes your acceptance of the updated terms.</p>
			
			<p><strong>11. Contact Information</strong></p>
            <p>If you have any questions, concerns, or inquiries regarding these Terms and Conditions, please contact us at:</p>
			 <ul>
              <li><strong>Email:</strong>digital@buzzglobalmarketing.com</li>
            </ul>

			<br>

			<p><strong>12. User Acknowledgment</strong></p>
            <p>By submitting an inquiry through our website, you acknowledge that you are providing your contact information voluntarily. The first communication sent to you will serve as an acknowledgment of your inquiry and may include additional details about the services you requested or marketing materials (if applicable).</p>
			<p>Thank you for choosing Buzz Global Marketing Pvt Ltd. We look forward to partnering with you to achieve your digital marketing and technology goals.</p>

			`}}></div>
        </div>
      </div>
    </div>
  </div>
</div>

			<CTA />
		</main>
	);
}

export default HomeMain;